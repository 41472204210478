<template>
  <div>
    <div class="card">1</div>
    <div class="card">2</div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
  .card {
    border-radius: 5px;
    background: white;
    margin: 0 10px 10px 10px;
  }
</style>